<template>
  <base-loader v-if="loading"></base-loader>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
    </base-header>
    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <card shadow type="secondary">
            <template v-slot:header>
              <div class="bg-white border-0">
                <div class="row align-items-center">
                  <div class="col-8">
                    <h3 class="mb-0">{{ $t("form.company_form") }}</h3>
                  </div>
                </div>
              </div>
            </template>

            <form @submit.prevent="submitForm">
              <!-- Errors Handleing -->
              <div
                class="row bg-danger py-3 mb-3"
                v-if="validationErrors.length"
              >
                <div
                  class="col-md-6"
                  v-for="(error, index) in validationErrors"
                  :key="index"
                >
                  <span class="text-white mr-2 error-handling">{{
                    error
                  }}</span>
                </div>
              </div>
              <h6 class="heading-small text-muted mb-4">
                {{ $t("form.company_information") }}
              </h6>
              <div class="pl-lg-4">
                <div class="row">
                  <div class="col-lg-6">
                    <base-input
                      alternative=""
                      :label="$t('form.name')"
                      :placeholder="$t('form.name')"
                      input-classes="form-control-alternative"
                      v-model="companyData.name"
                    />
                  </div>
                  <div class="col-lg-6">
                    <base-input
                      alternative=""
                      :label="$t('form.email')"
                      placeholder="abc@example.com"
                      input-classes="form-control-alternative"
                      v-model="companyData.email"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <base-input
                      alternative=""
                      :label="$t('form.sector')"
                      :placeholder="$t('form.sector')"
                      input-classes="form-control-alternative"
                      v-model="companyData.sector"
                    />
                  </div>
                </div>
              </div>
              <hr class="my-4" />
              <!-- Information -->
              <h6 class="heading-small text-muted mb-4">
                {{ $t("form.additional information") }}
              </h6>
              <div class="pl-lg-4">
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group has-label">
                      <label class="form-control-label">{{
                        $t("form.logo")
                      }}</label>
                      <input
                        type="file"
                        class="form-control form-control-alternative"
                        v-on:change="uploadLogo"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group has-label">
                      <label class="form-control-label">{{
                        $t("form.avatar")
                      }}</label>
                      <input
                        type="file"
                        class="form-control form-control-alternative"
                        v-on:change="uploadAvatar"
                      />
                    </div>
                  </div>
                  <div class="col-lg-6" v-if="!this.$route.params.id">
                    <label class="form-control-label">{{
                      $t("form.gender")
                    }}</label>
                    <select
                      class="form-control form-control-alternative"
                      v-model="companyData.gender"
                    >
                      <option value="" selected>
                        {{ $t("form.choose_gender") }}
                      </option>
                      <option value="male">
                        {{ $t("form.male") }}
                      </option>
                      <option value="female">
                        {{ $t("form.female") }}
                      </option>
                    </select>
                  </div>
                  <div class="col-lg-6">
                    <base-input
                      type="password"
                      alternative=""
                      :label="$t('form.password')"
                      :placeholder="$t('form.password')"
                      input-classes="form-control-alternative"
                      v-model="companyData.password"
                    />
                  </div>
                </div>
              </div>
              <hr class="my-4" />
              <div class="col-lg-12 mt-4">
                <button type="submit" class="btn btn-success d-block">
                  {{ this.$route.params.id ? $t("form.save") : $t("form.add") }}
                </button>
              </div>
            </form>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "company-form",
  data() {
    return {
      loading: false,
      validationErrors: [],

      companyData: {
        name: "",
        email: "",
        sector: "",
        gender: "",
        password: "",
        logo: "",
        avatar: "",
      },
    };
  },
  created() {
    if (this.$route.params.id) {
      this.getCompany(this.$route.params.id);
    }
  },
  methods: {
    validation: function () {
      this.validationErrors = [];
      if (
        this.companyData.name &&
        this.companyData.email &&
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
          this.companyData.email
        ) &&
        this.companyData.sector &&
        (this.companyData.gender || this.$route.params.id)
      ) {
        return true;
      } else {
        if (!this.companyData.name) {
          this.validationErrors.push(`${this.$t("validation.name required")}`);
        }
        if (!this.companyData.email) {
          this.validationErrors.push(`${this.$t("validation.email required")}`);
        }
        if (
          this.companyData.email &&
          !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
            this.companyData.email
          )
        ) {
          this.validationErrors.push(
            `${this.$t("validation.email not valid")}`
          );
        }
        if (!this.companyData.sector) {
          this.validationErrors.push(
            `${this.$t("validation.sector required")}`
          );
        }
        if (!this.companyData.gender && !this.$route.params.id) {
          this.validationErrors.push(
            `${this.$t("validation.gender required")}`
          );
        }
        if (!this.companyData.password && !this.$route.params.id) {
          this.validationErrors.push(
            `${this.$t("validation.password required")}`
          );
        }
        return false;
      }
    },
    submitForm() {
      if (this.validation() == false) {
        this.$swal({
          position: "center",
          icon: "error",
          title: `${this.$t("validation.faild")}`,
          showConfirmButton: false,
          timer: 1200,
        });
        return false;
      }
      this.loading = true;
      if (this.$route.params.id) {
        this.companyData._method = "put";
        let formData = this.toFormData(this.companyData);
        this.updateCompany(formData);
      } else {
        let formData = this.toFormData(this.companyData);
        this.addCompany(formData);
      }
    },
    // Get Company Information
    getCompany: function () {
      this.loading = true;
      axios
        .get("admin/administration/companies/" + this.$route.params.id)
        .then((response) => {
          if (!response.data.error) {
            this.companyData = {};
            this.companyData.company_id = response.data.data[0].id;
            this.companyData.name = response.data.data[0].name;
            this.companyData.email = response.data.data[0].email;
            this.companyData.sector = response.data.data[0].sector;
            this.companyData.gender = response.data.data[0].gender;
          }
        })
        .catch(() => {
          if (!window.navigator.onLine) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
              text: `${this.$t("validation.check network")}`,
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    // Add Company
    addCompany: function (formData) {
      this.loading = true;
      axios
        .post("admin/administration/companies/store", formData)
        .then((response) => {
          if (response.data.error) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
            });
          } else {
            if (response.data.status == 1) {
              this.$swal({
                position: "center",
                icon: "success",
                title: `${this.$t("validation.success")}`,
                text: response.data.message,
                showConfirmButton: false,
                timer: 1200,
              });
              setTimeout(() => {
                this.$router.push("/companies");
              }, 1500);
            } else {
              this.validationErrors.push(response.data.message);
              this.$swal({
                position: "center",
                icon: "error",
                title: `${this.$t("validation.faild")}`,
                showConfirmButton: false,
                timer: 1200,
              });
            }
          }
        })
        .catch(() => {
          if (!window.navigator.onLine) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
              text: `${this.$t("validation.check network")}`,
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    // Update Company
    updateCompany: function (formData) {
      this.loading = true;
      axios
        .post("admin/administration/companies/update", formData)
        .then((response) => {
          if (response.data.error) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
            });
          } else {
            if (response.data.status == 1) {
              this.$swal({
                position: "center",
                icon: "success",
                title: `${this.$t("validation.success")}`,
                text: response.data.message,
                showConfirmButton: false,
                timer: 1200,
              });
              setTimeout(() => {
                this.$router.push("/companies");
              }, 1500);
            } else {
              this.validationErrors.push(response.data.message);
              this.$swal({
                position: "center",
                icon: "error",
                title: `${this.$t("validation.faild")}`,
                showConfirmButton: false,
                timer: 1200,
              });
            }
          }
        })
        .catch(() => {
          if (!window.navigator.onLine) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
              text: `${this.$t("validation.check network")}`,
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    // Get Image to Add
    uploadLogo(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.companyData.logo = files[0];
    },
    uploadAvatar(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.companyData.avatar = files[0];
    },
    toFormData: function (obj) {
      var fd = new FormData();
      for (var i in obj) {
        fd.append(i, obj[i]);
      }
      return fd;
    },
  },
};
</script>
